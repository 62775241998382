<div class="offerCard" id="offerCard" [ngClass]="showOnOfferPage ? 'h-100 pb-5' : ''" #offerCard>
  <div class="highlight" *ngIf="highlightIds | inArray : offer?.recno + ''; else noHighlight">
    <div [innerHTML]="translationService.getTranslation('offer_highlight_text')"></div>
  </div>
  <ng-template #noHighlight>
    <div class="noHighlight h-auto" *ngIf="highlightIds && highlightIds.length > 0"></div>
  </ng-template>
  <mat-card appearance="outlined" class="mat-elevation-z4 pointer" [ngClass]="showOnOfferPage ? 'h-100' : ''">
    <div class="heading h5 text-center" [innerHTML]="offer?.description">
    </div>
    <div class="offerImage">
    <img mat-card-image *ngIf="offer?.image"
         [src]="offer?.image" alt="Angebot">
    </div>
    <mat-card-content class="mt-3 h-100"
                      [innerHTML]="sanitizer.bypassSecurityTrustHtml(offer?.summary)"></mat-card-content>
    <div class="footer" *ngIf="showOnOfferPage">
      <ng-container *ngIf="offer?.priceCalculationActivated; else pricetext">
        <div class="text-center">
          <div class="h6">{{offer?.offerPrice?.price | currency}}</div>
          <div [innerHTML]="offer?.additionalPriceDescription"></div>
        </div>
      </ng-container>
      <ng-template #pricetext>
        <div class="text-center offerPrice">
          <div class="h6" [innerHTML]="offer?.priceCalculationSummary"></div>
        </div>
      </ng-template>
      <div class="text-center offerButton">
        <button mat-raised-button color="primary"
                (click)="openOrder(offer?.orderChoiceKey)" [innerHTML]="translationService.getTranslation('button_order')"></button>
      </div>
    </div>
    <div *ngIf="!showOnOfferPage" class="ms-3 mb-3">
      <div class="offerBonus" *ngIf="bonuses">
        <hr>
        <div *ngFor="let bonus of bonuses; let i = index">
          <div class="h6" [innerHTML]="translationService.getTranslation('step_orderBonus')"> {{i+1}}"></div>
          <div [innerHTML]="bonus.name" ></div>
        </div>
      </div>
      <ng-container *ngIf="deliveryAddress">
        <hr>
        <div class="h6" [innerHTML]="translationService.getTranslation('step_orderAddr')"></div>
        <div class="row offerDeliveryAddress">
          <div class="col-6" [innerHTML]="translationService.getTranslation('address_delivery')"></div>
          <div class="col-6">
            <ul class="list-unstyled">
              <li>
                {{deliveryAddress?.person?.salutation}} {{deliveryAddress?.person?.title}} {{deliveryAddress.person.initials ? deliveryAddress.person.initials : deliveryAddress.person.firstname}} {{deliveryAddress.person.middlename}} {{deliveryAddress.person.lastname}}
              </li>
              <li>
                {{deliveryAddress?.address?.street}} {{deliveryAddress?.address?.houseno | houseNoPipe}}
                 {{deliveryAddress?.address?.zipcode}} {{deliveryAddress?.address?.city}}
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="billingAddress && billingAddress.required">
        <div class="row offerBillingAddress">
          <div class="col-6" [innerHTML]="translationService.getTranslation('address_billing')"></div>
          <div class="col-6">
            <ul class="list-unstyled">
              <li>
                {{billingAddress?.person?.salutation}} {{billingAddress?.person?.title}} {{billingAddress.person.firstname}} {{billingAddress.person.lastname}}
              </li>
              <li>
                {{billingAddress?.address?.street}} {{billingAddress?.address?.houseno | houseNoPipe}}
                {{billingAddress?.address?.zipcode}} {{billingAddress?.address?.city}}
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="bonusAddress && bonusAddress.required">
        <div class="row offerBonusAddress">
          <div class="col-6" [innerHTML]="translationService.getTranslation('address_bonus')"></div>
          <div class="col-6">
            <ul class="list-unstyled">
              <li>
                {{bonusAddress?.person?.salutation}} {{bonusAddress?.person?.title}} {{bonusAddress.person.firstname}} {{bonusAddress.person.lastname}}
              </li>
              <li>
                {{bonusAddress?.address?.street}} {{bonusAddress?.address?.houseno | houseNoPipe}}
                 {{bonusAddress?.address?.zipcode}} {{bonusAddress?.address?.city}}
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="paymentCode || paymentFrequency">
        <hr>
        <div class="h6" [innerHTML]="translationService.getTranslation('step_orderPayment')"></div>
      </ng-container>
      <ng-container *ngIf="paymentCode">
        <div class="row offerPaymentCode">
          <div class="col-6" [innerHTML]="translationService.getTranslation('payment_type')"></div>
          <div class="col-6">{{paymentCode.description}}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="paymentFrequency">
        <div class="row offerPaymentFrequency">
          <div class="col-6" [innerHTML]="translationService.getTranslation('payment_rhythm')"></div>
          <div class="col-6">{{paymentFrequency.description}}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="startDate">
        <hr>
        <div class="h6" [innerHTML]="translationService.getTranslation('step_orderDateAndOptins')"></div>
        <div class="row offerStartDate">
          <div class="col-6" [innerHTML]="translationService.getTranslation('startdate')"></div>
          <div
            class="col-6">{{startDate | date}}</div>
        </div>
      </ng-container>
      <div #saveButtonPoint id="saveButtonPoint" style="visibility: hidden"></div>
      <ng-container *ngIf="prices">
        <hr>
        <div class="h6" [innerHTML]="translationService.getTranslation('step_check')"></div>
        <div class="row mb-2">
          <div class="col-6" [innerHTML]="translationService.getTranslation('price')"></div>
          <ng-container *ngFor="let price of prices">
          <div *ngIf="offer?.priceCalculationActivated; else fixedPrice"
            class="col-6">{{ price?.price | number : '1.2-2'}} {{ price.currency}}
          <div *ngIf="price.porto" [innerHTML]="translationService.getTranslation('price_delivery') + ' ' + (price?.porto | number : '1.2-2') + ' ' + price.currency">
          </div></div>
            <ng-template #fixedPrice>
              <div class="col-6">{{ price?.priceText}}</div>
            </ng-template>
            <div class="col-6"></div>
          </ng-container>
        </div>

        <ng-container *ngIf="!privacyDisclaimerAgbInOne; else allInOne">

          <div class="col-12">
            <mat-checkbox [(ngModel)]="agb" required class="text-wrap" #agbBox data-cy="checkbox-agb">
              <div [innerHTML]="translationService.getTranslation('order_agbaccept_epaper')"></div>
            </mat-checkbox>
            <mat-error class="small errorOffset" *ngIf="!agbBox.checked && checkBoxes"
                       [innerHTML]="translationService.getTranslation('order_agbaccept_epaper_error')"></mat-error>
          </div>

          <div class="col-12">
            <mat-checkbox [(ngModel)]="disclaimer" required class="text-wrap" #disclaimerBox data-cy="checkbox-disclaimer">
              <div [innerHTML]="translationService.getTranslation('order_widerrufInfo')"></div>
            </mat-checkbox>
            <mat-error class="small errorOffset" *ngIf="!disclaimerBox.checked && checkBoxes"
                       [innerHTML]="translationService.getTranslation('order_widerrufInfo_error')"></mat-error>
          </div>

          <div class="col-12">
            <mat-checkbox [(ngModel)]="privacy" required class="text-wrap" #privacyBox data-cy="checkbox-privacy">
              <div [innerHTML]="translationService.getTranslation('order_privacyInfo')"></div>
            </mat-checkbox>
            <mat-error class="small errorOffset" *ngIf="!privacyBox.checked && checkBoxes"
                       [innerHTML]="translationService.getTranslation('order_privacyInfo_error')"></mat-error>
          </div>

        </ng-container>
        <ng-template #allInOne>

          <div class="col-12">
            <mat-checkbox [(ngModel)]="privacyDisclaimerAgb" class="text-wrap" required #privacyDisclaimerAgbBox
                          data-cy="checkbox-agb-disclaimer-privacy">
              <div [innerHTML]="translationService.getTranslation('order_privacyDisclaimerAgb')"></div>
            </mat-checkbox>
            <mat-error class="alignEnd" *ngIf="!privacyDisclaimerAgbBox.checked && checkBoxes"
                       [innerHTML]="translationService.getTranslation('order_privacyDisclaimerAgb_error')"></mat-error>
          </div>

        </ng-template>

        <div class="row" *ngIf="!onlinePayment">
          <div class="col text-center">
            <button mat-raised-button color="primary" [disabled]="creatingOrder"
                    (click)="saveOrder()" data-cy="btn-create-order"
                    [innerHTML]="(prices[0].price > 0 || !offer?.priceCalculationActivated)
                      ? translationService.getTranslation('button_orderSend')
                      : translationService.getTranslation('button_orderSendFree')"></button>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-card>
</div>
